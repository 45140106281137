
import { defineComponent } from 'vue'
import { ref, reactive } from 'vue'
import { formatTime } from '@/utils/dateTime'
import { RequestSeller, RequestSellerStatusEnum } from '@/utils/constants'
import { getOrderStatusColor } from '@/utils/orderUtils'
import axios from '@/services/axios'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {},
  setup(props, context) {
    const { error, success } = useNotification()
    const dialogVisible = ref(false)

    const requestOrder = ref()

    const state = reactive({
      status: ref(''),
      reason: ref(''),
      comment: ref(''),
    })

    const toggle = (order: any) => {
      // fill data
      requestOrder.value = order
      state.status =
        order.requestSellerStatus || RequestSellerStatusEnum.requested
      state.reason = order.requestSellerReasonCode
      state.comment = order.requestSellerComment

      // visible dialog
      dialogVisible.value = true
    }

    const userType = ref()
    let userInfo = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
      userType.value = userInfo.userType || null
      if (!requestOrder.value?.requestSellerStatus) {
        state.status =
          userType.value === 'seller'
            ? RequestSellerStatusEnum.updated
            : RequestSellerStatusEnum.requested
      }
    } catch (e) {
      return error("Can't find user info")
    }

    const updateRequestSellerOrder = async (orderId: string, dto: any) => {
      const res = await axios.put(`orders/${orderId}/request-seller`, dto)
      if (res?.status === 200 || res?.status === 201) {
        success('Updated!')
      } else {
        error('Error when send request!')
      }
    }

    const confirm = async () => {
      const orderId = requestOrder.value?._id?.toString()

      // validate
      if (!orderId) {
        error('Not found order!')
      }
      if (!state.status) {
        error('Status is null')
        return
      }
      if (!state.reason) {
        error('Reason is null')
        return
      }
      if (!state.comment) {
        error('Comment is null')
        return
      }

      const dto = {
        status: state.status,
        reasonCode: state.reason,
        comment: state.comment,
      }
      await updateRequestSellerOrder(orderId, dto)
      context.emit('onAfterResquestSeller')
      close()
    }

    const clear = () => {
      state.status = ''
      state.reason = ''
      state.comment = ''
    }

    const close = () => {
      dialogVisible.value = false
    }
    return {
      close,
      toggle,
      dialogVisible,
      formatTime,
      requestOrder,
      getOrderStatusColor,
      RequestSeller,
      state,
      confirm,
      RequestSellerStatusEnum,
      updateRequestSellerOrder,
      clear,
      userInfo,
      userType,
    }
  },
})
